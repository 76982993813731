
.mc-item-options {
  color: #ffffff;

  ion-item-option {
    font-weight: 500;
    width: 60px;
  }

  .item-option-disabled {
    font-weight: normal !important;
    opacity: 0.5;
    //color: #cccccc;
  }

  .confirm_option {
    width: 50%;
  }
}


.mc-item-option-1 {
  width: 130px !important;

  ion-item-option {
    width: 130px !important;
  }
}

.mc-item-option-2 {
  width: 90px*2 !important;

  ion-item-option {
    width: 90px !important;
  }
}

.mc-item-option-3 {
  width: 60px*3 !important;

  //.confirm_option {
  //  width: 90px !important;
  //}
}

.mc-item-option-4 {
  width: 60px*4 !important;
}