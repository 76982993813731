.model-button-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;

  //模板底部按钮，平分宽度，以 | 分隔
  ion-button {
    //height: 30px !important;
    //font-size: 14px !important;
    width: 100%;

    //&:nth-child(2), &:nth-child(3) {
    //  //position: relative;
    //
    //  &::after {
    //    content: ' ';
    //    border-right: solid 1px #C4C4C4;
    //    height: 80%;
    //    position: absolute;
    //    top: 7px;
    //    left: 50%;
    //  }
    //}
  }
}

