
.mc-collapse-btn {
  text-align: center;
  margin: auto;
  //width: 130px;
  width: 65%;
  left: 0;
  bottom: 0;
  right: 0;
  height: 39px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;

  .icon-block {
    font-size: 10px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 60px;
    background-color: #eeeeee;
    color: #666666;
    border: solid 1px #CCCCCC;
    position: absolute;
    bottom: -1px;
  }
}


.mc-collapse-node-btn {
  text-align: center;
  margin: auto;
  //width: 130px;
  width: 65%;
  left: 0;
  bottom: 0;
  right: 0;
  height: 39px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  z-index: 99;

  .icon-block {
    font-size: 10px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 60px;
    background-color: #eeeeee;
    color: #666666;
    border: solid 1px #CCCCCC;
    position: absolute;
    bottom: -1px;
  }
}
