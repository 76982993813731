quill-editor {
  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
    content: '标题1';
  }

  .ql-toolbar {
    padding: 0 8px !important;

    .ql-formats {
      & > button {
        height: 44px;
        padding: 11px 5px;
      }

      & > span {
        height: 44px;
        padding-top: 8px;

        svg {
          height: 22px;
          width: 22px;
        }
      }
    }
  }

  &.min-height-100 .ql-editor {
    min-height: 100px;
  }

  &.min-height-150 .ql-editor {
    min-height: 150px;
  }

  &.min-height-200 .ql-editor {
    min-height: 200px;
  }

  &.min-height-300 .ql-editor {
    min-height: 300px;
  }

  &.min-height-500 .ql-editor {
    min-height: 500px;
  }
}


//////////////////////////////////////////////////////////////////
// 富媒体预览
//.ql-editor {
//  padding-top: 3px;
//  line-height: 1.5em;
//  font-size: 15px;
//
//  h1 {
//    font-size: 1.35em !important;
//    padding: 8px 0;
//  }
//
//  h2 {
//    font-size: 1.2em !important;
//    padding: 5px 0;
//  }
//
//  pre.ql-syntax {
//    background-color: #efefef !important;
//    //color: #f8f8f2;
//    overflow: visible !important;
//    color: #333333 !important;
//    border: solid 1px #cccccc !important;
//  }
//
//}
.ql-toolbar.ql-snow .ql-formats {
  margin-right: 0 !important;
}

.ql-editor {
  font-size: 17px !important;
  line-height: 1.5em;
  white-space: normal !important;
  //height: calc(100% - 43px);

  //p {
  //  white-space: pre-wrap;
  //}

  h1 {
    font-size: 1.35em !important;
  }

  h2 {
    font-size: 1.2em !important;
  }

  .sub, sub {
    font-size: 90% !important;
  }

  pre.ql-syntax {
    //color: #f8f8f2;
    overflow: visible;
    //color: #333333;
    border: solid 1px #cccccc;
    background-color: #efefef !important;
    color: #000000 !important;
  }

  .ql-indent-1, ol, ul {
    text-indent: 1em !important;
    padding-left: 0 !important;
  }

  .ql-indent-2 {
    text-indent: 2em !important;
    padding-left: 0 !important;
  }

  .ql-indent-3 {
    text-indent: 3em !important;
    padding-left: 0 !important;
  }

  .ql-indent-4 {
    text-indent: 4em !important;
    padding-left: 0 !important;
  }

  .ql-indent-5 {
    text-indent: 5em !important;
    padding-left: 0 !important;
  }

  .ql-indent-6 {
    text-indent: 6em !important;
    padding-left: 0 !important;
  }

  .ql-indent-7 {
    text-indent: 7em !important;
    padding-left: 0 !important;
  }

  .ql-indent-8 {
    text-indent: 8em !important;
    padding-left: 0 !important;
  }
}

/////view
quill-view {
  white-space: normal !important;
  -webkit-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;

  width: 100%;

  .ql-container {
    white-space: normal !important;
    -webkit-user-select: text !important;
    -moz-user-select: text !important;
    -ms-user-select: text !important;
    user-select: text !important;
  }

  .ql-editor {
    padding: 0;

    p {
      white-space: normal !important;
      -webkit-user-select: text !important;
      -moz-user-select: text !important;
      -ms-user-select: text !important;
      user-select: text !important;
    }
  }
}