.mc-select {
  // padding-left: 5px; 与ion-input 一致
  //padding: 5px 5px 5px 0;
  padding: 0 5px;
}

.mc-select-menu {
  //background-color: rgba(0, 1, 1, 0.2);
  position: absolute;
  z-index: 300;
  //max-width: 500px;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px #e0e0e0, 0 6px 16px #ebebeb, 0 9px 28px 8px #f0f0f0;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
  padding: 5px 0;

  //color: rgba(0, 0, 0, 0.05);


  background-color: #ffffff;
  transform-origin: left top;

  ul {
    padding: 0 5px;
    margin: 0;
    overflow: auto;
    //max-height: 200px;
    width: 100%;

    .mc-select-focus {
      background-color: #e5e5e5;
    }

    .mc-select-selected {
      background-color: #ccd9fe;
    }

    li {
      cursor: pointer;
      //white-space: nowrap;
      list-style: none;
      padding: 6px;
      overflow: hidden;
      display: block;
      //text-overflow: ellipsis;
      //min-height: 28px;
      //line-height: 28px;

      //&:hover {
      //  background-color: #e5e5e5;
      //}
    }
  }
}


.mc-select-icon {
  width: 32px;
  height: 32px;
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  ion-icon {
    opacity: 0.6;
  }
}


//.mc-select-menu-multiple {
//  .mc-select-focus {
//    &:after {
//      content: '';
//      position: absolute;
//      top: 0;
//      left: 0;
//      width: 100%;
//      height: 100%;
//      background-color: rgba(0, 1, 1, 0.2);
//    }
//  }
//
//}
