.week-event-group {
  margin-top: 16px;

  dock-select {
    //font-size: 0.85em;
    position: absolute;
    right: 16px;
    font-weight: 400;
  }

  .week-event:first-child {
    margin-top: 10px;
  }

  ion-select {
    min-width: 65%;
  }
}

.week-event {
  margin: 20px 16px;

  .week-event-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--ion-color-tertiary);

    .week-event-calendar {
      ion-icon {
        font-size: 20px;
      }

      label {
        vertical-align: top;
        font-weight: 500;
        line-height: 22px;
        margin-left: 3px;
      }

      .week-number-right {
        position: absolute;
        right: 16px;
        color: var(--ion-color-warning);
      }
    }
  }

  .week-event-content {
    font-size: 17px;
    color: #555555;
    background: #ffffff;
    //padding: 10px 12px;
    list-style: none;
    margin: 8px 0;
    border-left: solid 3px var(--ion-color-tertiary);
    border-radius: 3px 0 0 3px;

    //&:has(ion-button) {
    //  padding-bottom: 40px;
    //}
    .week-event-content-btn {
      position: relative;
      //height: 36px;
      //padding-top: 10px;
      text-align: right;

      //ion-button {
      //  position: absolute;
      //  right: 0;
      //}
      ion-button {
        span {
          font-size: 16px;
        }
      }
    }
  }

  .week-event-new-btn {
    background: #ffffff;
    margin: 8px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    border-radius: 3px;
    border: solid 1px #e1e1e1;
    //color: #999999;
    color: var(--ion-color-danger);

    span {
      margin-left: 5px;
    }
  }

  .week-event-expired {
    background: #ffffff;
    margin: 8px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    border-radius: 3px;
    border: solid 1px #e1e1e1;
    color: #999999;

    span {
      //margin-left: 5px;
    }
  }

  .week-event-flag-bar {
    color: var(--ion-color-medium-tint);
    margin-top: 12px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    > div {
      margin-left: 20px;

      > ion-icon {
        margin-right: 3px;
      }

      > span {
        vertical-align: top;
      }
    }
  }

}

.week_report_select {
  text-align: right;
  padding: 0;
  color: var(--ion-color-primary);

  .alert-wrapper {
    width: 75%;
    max-width: 320px;
  }

  .alert-radio-group {
    max-height: 380px;
    padding: 10px;

    .alert-radio-button {
      height: 38px;
    }
  }
}