.flow-status-flag {
  font-weight: 500 !important;
  text-align: right;
  color: var(--ion-color-base) !important;
}


.apply-action-sheet-filter {
  ion-icon {
    width: 1.2rem;
    height: 1.2rem;
    mask-size: cover;
  }

  .all {
    ion-icon {
      mask: url(../assets/icon/bars-checked.svg) no-repeat 50% 50%;;
      background-color: var(--ion-color-primary);
    }
  }

  .stamp {
    ion-icon {
      mask: url(../assets/icon/stamp-checked.svg) no-repeat 50% 50%;;
      background-color: var(--ion-color-primary);
    }
  }

  .passport {
    ion-icon {
      mask: url(../assets/icon/passport-checked.svg) no-repeat 50% 50%;;
      background-color: var(--ion-color-primary);
    }
  }

  .restaurant {
    ion-icon {
      mask: url(../assets/icon/restaurant-checked.svg) no-repeat 50% 50%;;
      background-color: var(--ion-color-primary);
    }
  }

  .meeting {
    ion-icon {
      mask: url(../assets/icon/meeting-checked.svg) no-repeat 50% 50%;;
      background-color: var(--ion-color-primary);
    }
  }

  .holdmeeting {
    ion-icon {
      mask: url(../assets/icon/holdmeeting-checked.svg) no-repeat 50% 50%;;
      background-color: var(--ion-color-primary);
    }
  }

  .supervise {
    ion-icon {
      mask: url(../assets/icon/supervise-checked.svg) no-repeat 50% 50%;;
      background-color: var(--ion-color-primary);
    }
  }

  .archives {
    ion-icon {
      mask: url(../assets/icon/file-word.svg) no-repeat 50% 50%;;
      background-color: var(--ion-color-primary);
    }
  }


  .action-sheet-destructive {
    ion-icon {
      background-color: var(--ion-color-danger) !important;
    }
  }

  .action-sheet-title {
    font-size: 18px;
  }

  .action-sheet-button {
    font-size: 18px;
  }
}
