table.mc-calendar-table {
  width: 100%;
  margin: 15px 0;

  thead {
    th {
      text-align: left;
      text-indent: 13px;
      padding: 20px 0;
    }
  }

  .day-cell {
    border-top: solid 1px #cccccc;
  }

  td {
    width: 14.28%;
    text-align: center;
    //font-family: "number-font";
    font-size: 16px;

    //.day-label {
    //  border-radius: 5px;
    //  //background-color: red;
    //  margin: 5px 8px;
    //  padding: 5px;
    //}

    .today {
      background-color: red;
      color: #ffffff;
    }
  }

}

// 日历------年
table.mc-calendar-year-table {
  margin-bottom: 10px;
  vertical-align: top;
  width: 32%;
  display: inline-table;
  margin-right: 2%;

  thead th {
    text-align: left;
    font-size: 14px;
    padding: 8px 0 2px;
  }

  &:nth-child(3n) {
    margin-right: 0 !important;
  }

  td {
    font-size: 12px;
    width: 14.28%;
    text-align: center;
    height: 20px;
  }
}