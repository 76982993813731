$xcfb-border-color: #C8C8C8;
$xcfb-padding-size: 12px;

.xc-flow-block {
  background: #FFFFFF;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: $xcfb-padding-size;
  margin-bottom: $xcfb-padding-size;

  .xcfb-head {
    border-bottom: solid 1px $xcfb-border-color;
    padding-bottom: $xcfb-padding-size;
    position: relative;
    color: #999999;


    .xcfb-h-h1 {
      color: var(--ion-color-primary);
      line-height: 30px;
      font-weight: 600;
    }

    .xcfb-h-h2 {
      font-size: .9em;

      span {
        margin-right: 6px;
      }
    }

    .c-completed {
      color: var(--ion-color-primary);
      //font-weight: 500;
    }


    .xvfb-h-icon {
      position: absolute;
      right: 2px;
      top: 8px;
    }
  }

  .xcfb-body {

    .xcfb-b-item {
      padding-top: $xcfb-padding-size;
      padding-bottom: $xcfb-padding-size;
      border-bottom: solid 1px #C8C8C8;

      &:last-child {
        padding-bottom: 0;
        border-width: 0;
      }

      .xcfb-b-person {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .xcfb-b-content {
      padding: 6px 0;
    }

    .xcfb-b-autograph {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      line-height: 30px;
      font-size: 0.85em;
      padding-top: 5px;

      span {
        padding-right: 5px;
      }

      img {
        height: 30px;
        width: auto;
      }
    }

  }
}
