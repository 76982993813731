.mc-currency-label {
  span:first-child {
    display: block;
    font-size: 12px;
    padding-left: 15px;
    padding-top: 5px;
    line-height: 10px;
    font-weight: 800;
    color: #999999;
  }

  span:last-child {
    //font-family: "fixed-width-font";
    font-family: "price-font";
  }
}