
html.ios ion-modal.mc-datetime {
  --width: 290px;
  --height: 382px;
  --border-radius: 8px;

  ion-datetime {
    min-height: 382px;
  }


}

html.ios ion-modal.mc-datetime-time {
  --height: 390px !important;
}


html.ios ion-modal.mc-datetime-year {
  --height: 310px !important;

  ion-datetime {
    min-height: 310px !important;
  }
}


html.md ion-modal.mc-datetime {
  --width: 320px;
  --height: 531px;
  --border-radius: 8px;

  ion-datetime {
    height: 531px;
  }

}

//
//html.plt-desktop ion-modal.mc-datetime {
//  ion-datetime {
//
//    .datetime-calendar {
//      color: red;
//    }
//
//    .calendar-day {
//      font-size: 18px;
//      padding: 4px;
//    }
//  }
//}
