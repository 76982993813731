// 移动端专用css
html.plt-mobileweb {

  .grid-header-block {
    background-color: var(--ion-color-primary);
    color: #ffffff;
    padding: 0 16px;
    min-width: 100px;
    min-height: 40px;
    display: inline-block;
    line-height: 40px;
    //font-size: 1.1em;
    font-weight: 500;
    margin: 16px 0 8px;
  }


  .detail-padding-bottom {
    height: 30px;
  }

  .grid-form {
    ion-row {
      ion-col {
        padding: 0 1px;
        margin: 1px 0;

        ion-item {
          //margin: 3px 0 !important;
          margin: 0;
        }
      }
    }
  }

  .pc-small-detail {
    ion-label, ion-input, ion-item {
      font-size: 15px;
    }

    ion-label {
      margin-top: 3px;
      margin-bottom: 3px;
    }

    ion-row {
      background-color: transparent !important;

      ion-col {
        padding: 0 1px;
        margin: 1px 0;

        ion-item {
          --padding-bottom: 0;
          --padding-top: 0;
        }
      }
    }
  }
}