html.plt-desktop {
  --ion-font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif;

  .list-page {
    ion-fab {
      ion-fab-button {
        height: 48px;
        width: 48px;
        margin-top: 6px;
      }
    }
  }

  footer {
    height: auto;

    .footer-buttons {
      height: 60px;
      padding: 0 16px;
      display: flex;
      align-items: center;

      ion-button {
        height: 36px;
      }

      //padding: 20px 8px 15px 8px;
    }
  }

  ion-grid {
    //--ion-grid-width-md: 1000px !important;
  }

  .detail-padding-bottom {
    height: 80px;
  }

  //.grid-body-block {
  //  background-color: #ffffff;
  //
  //  ion-item {
  //    border-bottom: solid 1px #cccccc;
  //  }
  //}

  //dn-page-model-content-base {
  footer {
    ion-button {
      max-width: 55%;
      margin: 0 auto;
    }
  }

  .pc-mw-5em {
    min-width: 5em !important;
  }

  .pc-mw-6em {
    min-width: 6em !important;
  }

  .pc-mw-7em {
    min-width: 7em !important;
  }

  .pc-mw-8em {
    min-width: 8em !important;
  }

  .pc-mw-9em {
    min-width: 9em !important;
  }

  .pc-mw-10em {
    min-width: 10em !important;
  }

  .pc-mw-11em {
    min-width: 11em !important;
  }

  .pc-mw-12em {
    min-width: 12em !important;
  }

  .pc-mw-13em {
    min-width: 13em !important;
  }

  .pc-mw-14em {
    min-width: 14em !important;
  }

  .pc-mw-15em {
    min-width: 15em !important;
  }

  .pc-mw-16em {
    min-width: 15em !important;
  }
}

//ion-grid ion-row ion-col {
//  ion-item {
//    margin: 4px 0 !important;
//  }
//}