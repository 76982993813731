.select-department {
  margin-bottom: 8px;
  width: 100%;

  span {
    display: inline-block;
    //min-width: 3.5em;
    //padding-right: 8px;
    min-width: 50%;
  }
}

.select-person {
  width: 100%;
  margin-bottom: 8px;

  span {
    display: inline-block;
    min-width: calc(16.66% - 8px);
    padding-right: 8px;
    //min-width: 16.66%;
  }
}

.select-extstaff {
  margin-bottom: 8px;
  width: 100%;

  span {
    display: block;
    line-height: 25px;
    //min-width: 3.5em;
    //padding-right: 8px;
    //width: 100%;
  }
}

