/*
列表页菜单栏筛选下拉菜单部分
 */
mc-header-menu {

  // 类型筛选栏
  .filter-type-buttons {
    --min-height: 32px;
    padding: 3px 12px;
    margin: 5px 0;

    .filter-type-buttons-label {
      min-width: 5em;
      display: flex;
      align-items: center;
      margin: 5px 10px 5px 0;
      --padding-top: 0;
      --padding-bottom: 0;
      color: #777777;
      font-weight: 400;
      font-size: 15px;
    }

    ion-chip {
      min-height: 24px;
      font-size: 13px;
      padding: 0px 15px;
      //margin-right: 6px;
      margin: 0;
      border-radius: 8px;
      --color: #555;
    }

    .chip-selected {
      font-weight: 500;
      --background: var(--ion-color-primary);
      --color: #fff
    }

  }

  // 排序栏
  .sort-setting-panel, .filter-type-panel {
    //font-size: 15px;
    padding: 3px 12px;
    margin: 5px 0;

    .sort-setting-item, .filter-type-item {
      --padding-top: 0;
      --padding-bottom: 0;
      --min-height: 32px;
      color: #777;
      margin: 0;

      ion-label {
        //font-size: 15px;
        --padding-top: 0;
        --padding-bottom: 0;
      }

      ion-chip {
        font-size: 0.8em;
        padding: 10px;
        border-radius: 12px;
      }

      .sort-setting-label {
        //min-width: 6em;
        display: flex;
        align-items: center;
        margin: 5px 5px 5px 0;

        ion-icon {
          font-size: 16px;
          margin-right: 6px;
        }
      }

      .sort-setting-select {
        margin: 0;
      }
    }
  }

  // 搜索栏
  .filter-searchbar {
    position: relative;

    ion-searchbar {
      // 头部右侧筛选菜单
      //padding-bottom: 0;
      //padding-top: 4px;
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  // 筛选按钮块
  .filter-panel {
    margin: 0 12px;
    background: #ffffff;

    .filter-header {
      padding-top: 8px;
      padding-bottom: 8px;
      position: relative;
      font-size: 17px;
      font-weight: 500;
    }

    .filter-tags {
      padding: 6px 0;

      span {
        display: inline-block;
        padding: 8px;
        background-color: var(--ion-color-primary);
        color: var(--ion-color-primary-contrast);
        font-size: 14px;
        margin-right: 16px;
        margin-bottom: 8px;
        border-radius: 4px;
      }

      ion-list:first-child {
        margin: 0;
        padding: 0;
      }

      ion-button {
        font-weight: 400;
      }
    }

    .form-item-small {
      margin: 0;
    }

    //&:last-child {
    //  padding-bottom: 8px;
    //}

    &:first-child {
      padding-top: 8px;
    }

    ion-button:last-child {
      margin-right: 0;
    }


    .filter-inline-block {
      display: flex;
      align-items: center;
      width: 100%;

      ion-input {
        width: 45%;
        //display: inline-block;
        --padding-bottom: 5px;
        --padding-top: 5px;
      }

      .filter-inline-block-divider {
        display: inline-block;
        /* padding: 0 6px; */
        width: 10%;
        text-align: center;
      }

    }
  }

  // 筛选统计部分
  .filter-count {
    color: var(--ion-color-danger);
    font-size: 15px;
    display: flex;
    align-items: center;
    height: 100%;
  }

  // 按钮
  .filter-button {
    margin: 3px;
    height: 32px;
    --padding-end: 22px;
    --padding-start: 22px;
  }
}

html.plt-desktop {

  // 头部右侧筛选菜单
  mc-header-menu {
    font-size: 15px;

    .header-menu {
      // pc下左右间隔= 12+4=16px
      padding: 0 3px;

      .sort-setting-panel {
        ion-item {
          font-size: 15px;

          .sort-setting-label {
            font-size: 15px;
          }
        }
      }

      // 筛选按钮块
      .filter-panel {

        mc-datetime {
          font-size: 15px;
        }

        .filter-tags {
        }

        //font-size: 15px;
      }
    }
  }
}
