.mc-input-price {
  //font-family: "fixed-width-font";
  --padding-start: 0 !important;
}

.mc-input-price-label {
  position: absolute;
  background: #ffffff;
  //border: 2px solid #bbbbbb;
  box-shadow: #e5e5e5 0px 8px 12px;
  box-shadow: rgb(0 0 0 / 10%) 0px 8px 12px;
  z-index: 2;
  height: 20px;

  span {
    position: absolute;
    z-index: 1;
    text-align: right;
    width: 100%;
    padding-right: 16px;
    font-size: 12px;
    color: #777777;
  }
}

.mc-input-price-prefix {
  height: 33px;
  line-height: 33px;
}

.mc-input-price-currency {
  position: absolute;
  left: 1em;
  //font-family: "price-font";
}

:host-context(html.plt-mobileweb) {
  .mc-input-price-label {
    height: 13px;

    span {
      top: -5px;
    }
  }
}

:host-context(html.plt-desktop) {
  .mc-input-price-label {
    height: 20px;

    span {
      top: 0;
    }
  }
}