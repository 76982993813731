/* ios height 100% */
html, body {
  height: 100vh;
  min-height: 100vh;
}

html {
  position: relative;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.alert-wrapper {
  .alert-message {
    font-size: 1em;
  }
}

.hide {
  display: none;
}

.dark-color {
  background-color: #777777;
}

a {
  text-decoration: none;
}

.no-margin {
  margin: 0 !important;
}

.form-item-no-margin {
  .form-item {
    margin: 0 !important;
  }
}

.clear-margin {
  margin: 0 !important;
}

.w100 {
  width: 100px !important;
}

.w80 {
  width: 80px !important;
}

.mw-2em {
  min-width: 2em !important;;
}

.mw-4em {
  min-width: 4em !important;;
}

.mw-5em {
  min-width: 5em !important;;
}

.mw-6em {
  min-width: 6em !important;;
}

.mw-7em {
  min-width: 7em !important;;
}

.mw-8em {
  min-width: 8em !important;;
}

.mw-9em {
  min-width: 9em !important;;
}

.mw-10em {
  min-width: 10em !important;;
}

.mw-11em {
  min-width: 11em !important;;
}

.mw-12em {
  min-width: 12em !important;;
}

.mw-13em {
  min-width: 13em !important;;
}

.mw-14em {
  min-width: 14em !important;;
}

.mw-15em {
  min-width: 15em !important;;
}

.fs-15px {
  font-size: 15px !important;
}

.fs-16px {
  font-size: 16px !important;
}

.fs-1rem {
  font-size: 1rem !important;
}

.mr5 {
  margin-right: 5px;
}

.mr8 {
  margin-right: 8px !important;
}

.mr16 {
  margin-right: 16px
}

.mt0 {
  margin-top: 0 !important;
}


.mt10 {
  margin-top: 10px;
}

.mb10 {
  margin-bottom: 10px;
}

.ml5 {
  margin-left: 5px;
}

.ml6 {
  margin-left: 6px;
}

.ml10 {
  margin-left: 10px;
}

.wh16 {
  width: 16px !important;
  height: 16px !important;
}

:focus {
  outline: none;
}

.selection {
  -webkit-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}

.loading-flag {
  padding: 10%;
  //text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

//ion-button {
//  --color: #ffffff;
//}

.bg-gray {
  --background: #E5E5E5;
  background: #E5E5E5;
}

.bg-gray-light {
  --background: #F6F6F6;
  background: #F6F6F6 !important;
  z-index: 1;
}

.bg-color-primary {
  --background: var(--ion-color-primary);
  background: var(--ion-color-primary);
}

.bg-color-danger {
  --background: var(--ion-color-danger);
  background: var(--ion-color-danger);
}

.bg-color-success {
  --background: var(--ion-color-success);
  background: var(--ion-color-success);
}

.bg-color-warning {
  --background: var(--ion-color-warning);
  background: var(--ion-color-warning);
}

.bg-color-primary {
  --background: var(--ion-color-primary);
  background: var(--ion-color-primary);
}

.color-danger {
  color: var(--ion-color-danger) !important;
}

.color-warning {
  color: var(--ion-color-warning) !important;
}

.color-dark {
  color: var(--ion-color-dark) !important;
}

.color-black {
  color: #000000 !important;
}

.color-primary {
  color: var(--ion-color-primary) !important;
}

.color-secondary {
  color: var(--ion-color-secondary) !important;
}

.color-tertiary {
  color: var(--ion-color-tertiary) !important;
}

.color-success {
  color: var(--ion-color-success) !important;
}

.bg-primary {
  background-color: var(--ion-color-primary) !important;
}

.bg-secondary {
  background-color: var(--ion-color-secondary) !important;
}

.bg-tertiary {
  background-color: var(--ion-color-tertiary) !important;
}


@font-face {
  font-family: 'number-font';
  //src: url('https://qw-1314738843.cos.ap-chongqing.myqcloud.com/static/font/number.ttf');
  src: url('https://qw-1314738843.cos.ap-chongqing.myqcloud.com/static/font/number.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'price-font';
  //src: url('../assets/font/Montserrat-Regular.ttf');
  //Medium
  src: url('https://qw-1314738843.cos.ap-chongqing.myqcloud.com/static/font/Montserrat-Medium.ttf');
  //font-weight: bold;
  //font-style: normal;
}

@font-face {
  font-family: 'fixed-width-font';
  //src: url('https://qw-1314738843.cos.ap-chongqing.myqcloud.com/static/font/LiberationMono-Regular-4.ttf');
  src: url('https://qw-1314738843.cos.ap-chongqing.myqcloud.com/static/font/RobotoMono-Regular-9.ttf');
  //font-weight: bold;
  //font-style: normal;
}

// 输入金额使用
.money-zh {
  position: relative;
  height: 20px;
  width: 100%;
  padding-right: 16px;

  span {
    position: absolute;
    font-size: 13px;
    color: #666666;
    text-align: right;
    right: 26px;
    top: 8px;
    z-index: 10;
  }
}

.divider {
  background-color: rgb(245, 245, 245);
  height: 8px;
}

.line-row {
  background-color: #eeeeee;
  height: 1px;
  margin: 0 auto;
}

.loading-spin {
  position: fixed;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);

  ion-spinner {
    width: 28px;
    height: 28px;
    stroke: #444;
    fill: #222;
  }
}

.width-100 {
  width: 100% !important;
}

.width-full {
  min-width: 100% !important;
  width: 100% !important;
}

.max-width-full {
  max-width: 100% !important;
}

// 水平平分下面的div
.divide {
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    width: 100%;
  }
}

////////////////////////// mc //////////////////////////
.mc-alert {
  --min-width: 160px;
  --max-width: 200px;
  //--width: 200px;
  --border-radius: 5px !important;
  --background: #333333;
  --color: #ffffff;
  text-align: center;
}

.mc-error, .mc-success, .mc-warn, .mc-default {
  --min-width: 180px;
  --max-width: 280px;
  //--width: 200px;
  --border-radius: 5px !important;
}

///////////////////////// 表单 /////////////////////////
.form-input {
  ion-label:first-child {
    color: var(--ion-color-primary) !important;
  }
}

.from-input-price {
  ion-input {
    margin-right: 0;
    text-align: right;
  }

  ion-label:last-child {
    margin-left: 0;
  }
}

.form-item {
  margin: 8px 0;
  --min-height: 50px;
  --padding-start: 16px;
  --padding-end: 16px;
  --border-color: #CCCCCC;
  --inner-padding-end: 0px;

  ion-button {
    height: 30px;
    font-size: 14px;
    --padding-start: 0.75em;
    --padding-end: 0.75em;
  }

  ion-label:first-child {
    font-size: 17px;
    min-width: 5em;
    max-width: 5em;
    color: #777777;
    font-weight: 400;

    &.required {
      position: relative;

      &:after {
        content: " *";
        font-size: 14px;
        color: var(--ion-color-danger);
      }
    }
  }

  ion-label:last-child {
    user-select: text !important;
  }

  ion-list-header {
    font-size: 17px;
  }

  ion-item-divider, ion-list-header {
    //position: relative;
    --inner-padding-end: 0px;
    height: 40px;
    padding-bottom: 3px;
    --padding-start: 16px;
    --padding-end: 16px;
    background-color: #F6F6F6;

    ion-label:first-child {
      color: var(--ion-color-tertiary);
      //color: var(--ion-color-dark);
      font-weight: 500;
      font-size: 1.05em;
      position: relative;
      padding-left: 12px;

      span:first-child {
        color: #181818;
      }

      &:before {
        content: " ";
        position: absolute;
        width: 4px;
        background-color: var(--ion-color-tertiary);
        height: 66%;
        border-radius: 2px;
        top: 17%;
        left: 0;
      }
    }
  }


  ion-item {
    --padding-start: 16px;
    --padding-end: 16px;
    --border-color: #CCCCCC;
    --min-height: 50px;
    --inner-padding-end: 0px;

    ion-label:first-child {
      min-width: 5em;
      max-width: 5em;
      color: #777777;
      font-weight: 400;
    }

    ion-note {
      //padding-left: 5px;
      margin-top: 12px;
      color: var(--ion-color-dark);
      margin-bottom: 10px;
    }

  }

  ion-label:not(:first-child), ion-input, ion-textarea, .text-full {
    font-weight: 400;
    color: var(--ion-color-dark);
  }

  ion-select.md {
    --padding-start: 8px;
    //min-width: 100%;
  }

  ion-datetime {
    width: 100%;
  }

  ion-datetime::part(text) {
    font-weight: 500;
    color: var(--ion-color-dark);
  }

  .right-border:after {
    content: " ";
    border-right: solid 1px #D8D8D8;
    height: 20px;
  }

  .left-border:after {
    content: " ";
    border-left: solid 1px #D8D8D8;
    height: 20px;
  }

  .label-button {
    --color: #00D1FF !important;
    text-decoration: none;
  }

  .checkbox-inline {
    align-items: center;
    display: flex;
    position: relative;
    //flex: 1 !important;
    width: 100%;
    height: 40px;

    .checkbox-item {
      margin-right: 24px;
      position: relative;
      width: 100%;
      height: 40px;

      .checkbox {
        position: absolute;
        white-space: nowrap;

        ion-checkbox, ion-label {
          display: inline-block;
          vertical-align: middle;
        }

        ion-checkbox {
          width: 22px;
          height: 22px;
        }
      }
    }
  }

  .info-title {
    color: #666666;
  }

  .info-content {
    font-size: inherit;
    font-weight: 500;
  }


  .info-block {
    padding: 8px 0;
    color: var(--ion-color-dark) !important;
    font-weight: 500;

    span {
      padding-right: 8px;
    }
  }

  ion-input[type=hidden] {
    display: none;
  }

  ion-select {
    display: flex;
    flex: 1;
    max-width: 100%;
    --padding-end: 18px;
    --padding-start: 0;

    &::part(icon) {
      display: none;
    }

    &::part(text) {
      //  background-image: url(https://www.iconfinder.com/data/icons/ios-11-ui-elements-vol-1/29/25_dropdown_menu_down_arrow-512.png);
      //  background-position: right;
      //  background-repeat: no-repeat;
      //  background-size: 50%;
      white-space: pre-line;
    }
  }

  ion-datetime {
    --padding-start: 0;
  }

  ion-note {
    -webkit-user-select: text !important;
    -moz-user-select: text !important;
    -ms-user-select: text !important;
    user-select: text !important;
  }

  // 整行文本块使用
  .text-full {
    color: var(--ion-color-dark);
    min-width: 100% !important;
    width: 100% !important;
    white-space: normal !important;
    -webkit-user-select: text !important;
    -moz-user-select: text !important;
    -ms-user-select: text !important;
    user-select: text !important;
  }

  //.xh {
  //  text-align: center;
  //  min-width: 2em;
  //  max-width: 2em;
  //  font-family: "number-font";
  //}
}

.form-item-small {
  padding: 3px 0;

  ion-item {
    //--max-height: ;
    margin: 0;
    --min-height: 40px;

    &:last-child {
      --inner-border-width: 0;
    }

    ion-label, ion-datetime {
      --padding-top: 8px;
      --padding-bottom: 8px;
    }
  }

  .form-item-header {
    //--min-height: 30px;
    span {
      font-weight: 400;
      margin-bottom: 0;
      margin-top: 5px;
    }
  }

  .header-checked {
    color: var(--ion-color-primary);

    span {
      font-weight: 600;
    }
  }

  .label-full-width {
    margin: 0;
    padding: 0 !important;
    min-width: 100% !important;
  }
}

ion-item.ion-invalid {
  border-bottom: solid 2px var(--ion-color-danger);
  margin-bottom: 0;
  --inner-border-width: 0px;
  height: auto !important;
}

///////////////////////// end 表单 /////////////////////////


.btn-footer {
  filter: drop-shadow(0px -2px 4px rgba(166, 166, 166, 0.25));

  background: #ffffff;
  height: 100px;
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 20px 16px 15px 16px;
  z-index: 99;

  ion-button {
    --border-radius: 4px;
  }
}

.air-btn {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  position: absolute;
  z-index: 1000;
}

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
//[contenteditable] {
//  -webkit-user-select: text;
//  user-select: text;
//}
//
.upload-item {
  --min-height: 40px;
  --padding-start: 16px;
  --padding-end: 16px;
  --border-color: #cccccc;
  --inner-padding-end: 0px;

  div {
    width: 100%;
    display: table;

    .upload-name {
      display: table-cell;
      width: calc(100% - 10em);
      color: var(--ion-color-primary);
    }

    .upload-size, .upload-date {
      display: table-cell;
      color: #666666;
      width: 5em;
      min-width: 5em;
      vertical-align: middle;
    }
  }
}


.text-label {
  font-weight: 400;
  color: var(--ion-color-dark);
}

.text-invalid {
  border: 2px solid var(--ion-color-danger) !important;
}


footer {
  z-index: 90;
  height: 100px;
  background-color: #ffffff;
  filter: drop-shadow(0px -3px 4px #ededed);
  filter: drop-shadow(0px -2px 4px rgba(166, 166, 166, 0.2));

  .footer-buttons {

    background: #ffffff;
    height: 100px;
    width: 100%;
    //position: fixed;
    bottom: 0;
    padding: 20px 8px 15px 8px;
    z-index: 99;

    ion-button {
      --border-radius: 8px;
    }
  }
}


.col-1 {
  width: 100%;
  display: block;
  vertical-align: top;
  padding: 0 8px;
}

.col-2 {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  padding: 0 8px;
}

.col-3 {
  width: 33.33%;
  display: inline-block;
  vertical-align: top;
  padding: 0 8px;
}

.ck-content {
  h1 {
    font-weight: 700;
    font-size: 1.25em !important;
  }

  h2 {
    font-weight: 600;
    font-size: 1.2em !important;
  }

  h3 {
    font-weight: 500;
    font-size: 1.12em !important;
  }
}


ion-tab-bar {
  filter: drop-shadow(0px -2px 4px rgba(166, 166, 166, 0.25));

  ion-icon {
    //  fill: none !important;
    //  stroke: var(--ion-color-dark);
    font-size: 24px;
    color: var(--ion-color-dark);
  }

  ion-label {
    font-size: 14px;
    color: var(--ion-color-dark);
  }

  .selected {
    //stroke: var(--ion-color-primary) !important;
    color: var(--ion-color-primary) !important;
  }
}


.modal-panel {
  padding-top: 5px;
  text-align: left;
  //padding: 3px 16px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 23px rgba(0, 0, 0, 0.12);

  .modal-title {
    margin: 3px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 15px;
    //padding: 15px;
    border-bottom: solid 1px #ccc;
    //color: #000000;

    ion-icon {
      font-size: 20px;
      margin-right: 3px;
    }

    label {
      vertical-align: top;
      font-weight: 500;
      line-height: 20px;
      margin-left: 3px;
    }
  }

  .modal-header {
    padding: 3px 16px;
    font-weight: 500;

    .modal-header-left-title {
      display: flex;
      align-items: center;
      padding-top: 8px;

      ion-icon {
        margin-right: 3px;
      }
    }
  }

  .modal-body {
    overflow: auto;
    padding: 3px 16px;

    ion-item {
      --padding-start: 0;
      --padding-end: 0;
      --inner-padding-end: 0px;
    }
  }

  .modal-footer {
    padding: 3px 16px;

    ion-button {
      height: 30px !important;
      //font-size: 17px !important;
    }

    .col-2 {
      position: relative;
    }

    .col-2::after {
      content: ' ';
      border-right: solid 1px #C4C4C4;
      height: 16px;
      position: absolute;
      top: 12px;
      right: 0;
    }

    .col-2:last-child::after {
      border-right: none;
    }
  }
}


.mc-modal-fill {
  .mc-modal-wrapper {
    .modal-panel {
      border-radius: 0;

      .modal-title {
        margin-top: 0;
      }

      .modal-body {
        height: calc(100vh - 40px - 60px);
      }
    }
  }
}


.detail-bottom-padding-block {
  height: 60px;
}

.bottom-padding-block {
  height: 260px;
}

.welcome {
  height: 100%;
  max-width: 500px;
  background-color: #ffffff;
  text-align: center;
  //margin: 0 auto;

  img {
    width: 60%;
    margin-top: 50%;
    max-width: 300px;
    //margin: 50% auto;
  }

  .footer {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 7%;
    font-size: 18px;
    left: 0;

    span {
      display: block;
      color: var(--ion-color-dark);
    }

    small {
      display: block;
      color: #999999;
    }
  }
}


// 附件ion-item调整高度
ion-list .attach-item {
  //--min-height: 40px !important;
  margin-bottom: 0 !important;
}

// 模拟placeholder
ion-datetime::part(placeholder), ion-select::part(placeholder), .placeholder {
  font-weight: 400 !important;
  opacity: .5 !important;;
  color: var(--ion-color-dark) !important;;
  white-space: nowrap !important;;
}

.html-view-more {

  .ql-editor {
    height: 100px;
    overflow-y: hidden;
  }
}

.html-view-btns {
  position: relative;
  height: 35px;

  .html-view-more-btn {
    position: absolute;
    height: 28px;
    width: 80px;
    background-color: #ffffff;
    right: 18px;
    text-align: right;
    line-height: 28px;
    font-size: 14px;
    color: var(--ion-color-primary);
  }
}


.detail-item-title {
  //color: var(--ion-color-dark) !important;
  //font-weight: 500 !important;
  //font-size: 18px !important;
  color: rgb(102, 102, 102) !important
}


.color-success {
  color: var(--ion-color-success) !important;
}

.color-reject {
  color: var(--ion-color-danger) !important;
}

////////////////////////////
// pc端下使用
//ion-content {
//  user-select: text;
//  -webkit-user-select: text;
//}

// 支持换行和文字可选
.text-wrap {
  white-space: normal !important;
  -webkit-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}


.member-avatar-label {
  cursor: pointer;
  display: flex !important;
  justify-content: center;
  align-items: center;

  img {
    border: solid 2px var(--background);
    border-radius: 50%;
    height: 26px;
    width: 26px;
    overflow: hidden;
    margin-right: 2px;
  }

  .member-name {
    display: inline-block;
    height: 26px;
    line-height: 26px;
    color: var(--ion-color-primary);
  }

}

// 员工信息块
.persons-block {
  margin: 10px 0;
  //display: flex;
  //justify-content: flex-end;
  text-align: right;
  width: 100%;

  dn-member-avatar {
    display: inline-block;
    margin-left: 6px;
  }
}

// pc-web
ion-split-pane.pc-web {
  --side-min-width: 300px;
  //--side-max-width: 500px;
  --border: solid 2px #ccc;

  ion-menu {
    > :first-child {
      height: 100%;
    }
  }
}

// pc-web 兼容问题
super-tabs-container {
  width: 100%;

  super-tab {
    width: 100%;
  }
}

// ion-label position="stacked" 安卓下字体变小的问题
.label-stacked.sc-ion-label-md-h {
  -webkit-transform: translateY(50%) scale(1);
  transform: translateY(50%) scale(1);
}

// ion-note
ion-note.md {
  font-size: 17px;
}


.footer-modal {
  padding-top: 10px;
  background-color: #F6F6F6 !important;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  //padding: 3px 10px;

  .modal-header {
    //margin: 10px 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;

    .modal-header-left-title {
      padding-top: 0 !important;
    }

    .footer-modal-header-close {
      position: absolute;
      right: 0;

      ion-icon {
        color: #cccccc;
        font-size: 18px;
      }
    }
  }

  .footer-modal-title {
    display: flex;
    align-items: center;
  }

  .modal-button {
    font-size: 17px;
    margin: 10px 0;
    border-radius: 12px;
    --inner-border-width: 0;
    --inner-padding-start: 20px;
    --inner-padding-end: 20px;

    ion-icon {
      font-size: 20px;
      margin-right: 12px;
    }
  }

  .modal-body {
    form {
      ion-item {
        --padding-start: 16px !important;
        --padding-end: 16px !important;
      }
    }
  }

  .modal-footer {
    padding-bottom: 20px;

    ion-button {
      height: 40px !important;
      //font-size: 17px !important;
    }
  }
}


.tag-block {
  cursor: pointer;
  min-height: 25px;
  line-height: 25px;
  padding: 0 6px;
  border-radius: 3px;
  margin: 3px 5px 3px 0;
  display: inline-block;
  color: #ffffff;
  font-size: 15px;

  &:last-child {
    margin-right: 0;
  }
}

.select-tag-block {
  opacity: 0.75;
  padding: 3px 10px;
  min-height: 28px;
  margin: 5px 10px 5px 0 !important;
  font-size: 1em !important;
  border: solid 2px #ffffff;
}

.selected-tag-block {
  opacity: 1;
  border: solid 2px var(--ion-color-primary);
}

.signature-modal {
  align-items: flex-end;

  &::part(content) {
    height: 305px;
  }
}


.dn-echarts-tooltip {
  .dn-echarts-tooltip-title {
    font-size: 14px;
    font-weight: 500;
  }

  .dn-echarts-tooltip-block {
    display: flex;
    align-items: center;
  }

  .dn-echarts-tooltip--icon {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 4px;
    margin-right: 5px;
  }
}


.sc-ion-select-popover-ios {
  white-space: normal !important;
}

.zoom-80 {
  //zoom: 0.8;
}

.zoom-90 {
  zoom: 0.9;
}

.zoom-100 {
  zoom: 1;
}

.mc-back-top {
  ion-fab-button {
    margin-top: 6px;
    width: 48px;
    height: 48px;
  }
}

.mc-error {
  --max-height: 300px;
  user-select: text !important;

  &::part(container) {
    overflow: auto;
  }

  &::part(message) {

  }
}

.text-wrap-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}


// table =================================================================
table.table-view {
  background-color: #fff;
  border-collapse: collapse;
  width: 100%;
  font-size: 14px;

  td, th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #fefefe;;
  }

  tr:hover {
    background-color: #fafafa;
  }

  thead th {
    position: sticky;
    top: 0;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #f2f2f2;
    font-weight: 500;
  }

  tbody {
    font-size: 0.9em;
  }
}


.dn-tags-input {
  background: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.07);
  border-radius: 12px;
  min-height: 36px;
  padding: 0 6px;

  ::placeholder {
    font-weight: 400 !important;
    opacity: .5 !important;;
  }

  tag {
    padding-top: 0 !important;
    font-size: 0.8em !important;
  }

  .ng2-tag-input {
    padding: 0 !important;
    min-height: auto !important;
    border-width: 0 !important;


    tag-input-form {
      input {
        color: var(--ion-color-dark);
        background: transparent;
        height: 36px;
      }
    }
  }
}
