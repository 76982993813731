
.mc-arrow-box {
  position: absolute;
  background: #FFFFFF;
  border: 1px solid #bbbbbb;
  box-shadow: #e5e5e5 0px 10px 15px -3px, #f2f2f2 0px 4px 6px -2px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  z-index: 6;
  width: 96%;
  margin: 0 1%;

  &:after, &:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(245, 245, 245, 0);
    border-bottom-color: #FFFFFF;
    border-width: 10px;
    margin-left: -10px;
  }

  &:before {
    border-color: rgba(187, 187, 187, 0);
    border-bottom-color: #bbbbbb;
    border-width: 11px;
    margin-left: -11px;
  }
}

.mc-history-table {
  //display: table;
  min-height: 100px;
  max-height: 300px;
  padding: 6px;
  width: 100%;
  overflow: auto;
  display: block;

  .mc-history-item-line {
    border-top: solid 1px #CCCCCC;
    padding-bottom: 5px;
    display: table-cell;
  }

  .mc-history-item {
    //display: flex;
    //justify-content: space-between;
    //align-items: center;
    display: table-row;

    .mc-history-header {

      &:first-child {
        width: 100%;
      }

      display: table-cell;
      text-align: center;
      font-size: 0.86em;
      font-weight: 500;
      padding: 3px 16px 6px;
      white-space: nowrap;
    }

    .mc-history-item-content {
      font-size: 0.8em;
      display: table-cell;
      word-break: break-all;
      padding: 3px 3px 3px 6px;
      width: 100%;
      vertical-align: middle;
    }

    .mc-history-item-info {
      font-size: 0.7em;
      display: table-cell;
      width: min-content;
      padding: 3px;
      text-align: center;

      .mc-history-staff {
        display: block;
      }

      .mc-history-date {
        white-space: nowrap;
      }
    }
  }
}


.mc-history-panel {
  min-height: 100px;
  max-height: 300px;
  padding: 6px;
  width: 100%;
  overflow: auto;
  display: block;

  .mc-history-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 6px 5px;
    border-bottom: 1px solid #cccccc;
    color: var(--ion-color-dark);
    margin-bottom: 3px;

    span:first-child {
      font-size: 0.85em;
      font-weight: 500;
    }

    .mc-history-close {
      display: flex;
      cursor: pointer;
      padding: 3px 6px;
    }
  }

  .mc-history-item {
    padding: 3px 6px;
    color: #505050;
    font-size: 0.75em;
  }

}