/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import 'video.js/dist/video-js.css';

/* self */
@import "theme/app.scss";
@import "theme/page-list.scss";
@import "theme/page-form.scss";
//@import "theme/page-detail.scss";
@import "theme/page-model.scss";
@import "theme/addressbook.scss";
@import "app/components/collapse/collapse.directive.scss";
@import "theme/dnh5.scss";
@import "theme/html-editor.scss";
@import "theme/mc-item-options.scss";
@import "theme/mc-datetime.scss";
@import "theme/mc-calendar-table.scss";
@import "theme/flow.scss";
@import "theme/week-event.scss";
@import "theme/desktop.scss";
@import "theme/mobileweb.scss";
@import "theme/mc-auto-popmenu.scss";
@import "theme/mc-select.scss";
@import "theme/mc-input-price.scss";
@import "theme/mc-data-history.scss";
@import "theme/dn-compact-panel.scss";
@import "theme/mc-currency-label.scss";
@import "theme/mc-header-menu-content.scss";
@import "theme/flow-v1";
