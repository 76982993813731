
ion-header.list-page {
  box-shadow: 0px 5px 8px #e0e0e0;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.12);

  ion-segment {
    height: 44px;
    background-color: #ffffff;
    border-radius: 0;
    //border-bottom: solid 6px #E5E5E5;
    margin: 0 16px 0 0;
    width: 100%;

    ion-segment-button {
      font-size: 16px;
      --color: var(--ion-color-dark);
      --border-width: 0;
      --color-focused: var(--ion-color-primary);
      --color-checked: var(--ion-color-primary);
      --border-radius: 0;
      --indicator-box-shadow: none;

      &::part(indicator) {
        //border-bottom: solid 3px var(--ion-color-primary);
        width: 60%;
        margin: 0 auto;
        border-radius: 2px;
      }
    }

  }

  ion-searchbar {
    --placeholder-color: #7F8592;
    --color: var(--ion-color-dark);
    //padding-bottom: 0;
    //padding-top: 8px;
  }

  //列表页页眉下拉菜单
  .header-menu {
    user-select: none;

    ion-item {
      //--min-height: 30px;
      --padding-start: 0;
      --padding-end: 0;

      .btn-block {
        height: 32px;
        --padding-start: 16px;
        --padding-end: 16px;
      }
    }


    .filter-chips {
      ion-chip.selected {
        font-weight: 500;
      }

      ion-chip {
        margin: 4px 3px;
        font-size: 0.859em;
        --background: #eee;
        --color: #999;
      }
    }

    .filter-tag-input {
      padding-bottom: 12px;
    }
  }


  ion-buttons.right-button {
    margin-right: 10px;

    ion-icon {
      margin-left: 0;
      font-size: 0.9em !important;
    }

    ion-label {
      font-size: 16px;
    }
  }

  ion-button.btn-close {
    color: var(--ion-color-primary);
  }


  ion-toolbar {
    --min-height: 50px;

    //左侧主题+下拉菜单
    .header-title {
      padding-left: 18px;
      text-align: left;
      font-size: 16px;
      color: var(--ion-color-primary);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 44px;
      background: #ffffff;
      margin-right: 16px;
      font-weight: 450;

      ion-icon {
        font-size: 20px;
      }
    }

    .header {
      padding: 0 16px;
      text-align: left;
      font-weight: 600;
      font-size: 1rem;
      overflow: auto;
      word-break: break-all;
    }
  }

  // 头部左侧下拉菜单
  .title-menu {
    ion-list {
      padding-bottom: 16px;

      ion-list-header {
        height: 30px;

        ion-label {
          font-weight: 400;
          margin: 6px 0 0 0;
          font-size: 14px;
          color: var(--ion-color-medium);
        }
      }

      ion-item {
        --padding-start: 20px;
        --padding-end: 20px;
        --inner-padding-end: 0px;
        //ion-checkbox {
        //  --size: 20px;
        //}
        ion-radio {
          margin-right: 3px;
        }

        // 最后一行不显示底部边线
        &:last-child {
          --inner-border-width: 0;
        }
      }
    }
  }

  .title-label {
    padding: 0 16px;
    text-align: left;
    font-size: 16px;
    overflow: auto;
    word-break: break-all;
  }


  //.filter-footer {
  //  margin: 0 12px;
  //  background: #ffffff;
  //  border-top: solid 1px #cccccc;
  //}

}


ion-content.list-page {

  ion-breadcrumbs {
    padding: 3px 0;
    margin: 12px 16px;
    background: #ffffff;
    box-shadow: 0px 3px 8px #e0e0e0;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12);

    ion-breadcrumb {
      &:last-child {
        word-wrap: break-word;
        overflow: hidden;
        //word-break: nowrap;
        //width: calc(100% - 16px);
        max-width: 100%;
        //display: block;
        //padding-right: 1em;
        color: #999;
      }

    }
  }

  .group-item {
    cursor: pointer;
    background: #ffffff;
    box-shadow: 0px 3px 8px #e0e0e0;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    margin: 12px 16px;
    padding: 3px 0;
    --inner-border-width: 0;

    ion-icon {
      margin-right: 6px;
      font-size: 22px;
    }
  }

  ion-item-sliding.list-panel {
    margin: 12px 16px;
    width: auto;;
    background-color: #ffffff;
    //box-shadow: 0 4px 23px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 3px 8px #e0e0e0;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12);
    border-radius: 8px;

    .list-item-radius {
      background: #FFFFFF;
      box-shadow: 0 4px 23px #e0e0e0;
      box-shadow: 0 4px 23px rgb(0 0 0 / 12%);
      border-radius: 8px;
      //padding: 12px;
    }

    ion-item {
      --padding-start: 0px;
      --padding-end: 0px;
      --inner-padding-end: 0px;

      .panel-item {
        font-size: 16px;
        width: 100%;
        //margin: 6px;
        padding: 6px 12px;
        //background: #FFFFFF;

        .header {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        .title {
          font-weight: 600 !important;
          color: var(--ion-color-warning);
          font-size: 18px;
          padding-bottom: 6px;

          span {
            display: block;
            min-width: 100%;
          }

          .xh {
            text-align: left;
            min-width: 26px !important;
            max-width: 26px !important;
            min-height: 15px;
            color: var(--ion-color-medium);
            font-family: "number-font";
            display: inline-block;
          }

          .subject {
            font-weight: 500;
            font-size: 17px;
            //display: inline-block;
            color: var(--ion-color-primary)
          }
        }

        .item {
          margin: 3px 0;
          font-weight: 400;

          label {
            color: #666666;
            padding-right: 3px;
            width: 5.3em;
            display: inline-block;
          }

          > span {
            max-width: calc(100% - 5.5em);
            color: var(--ion-color-dark);
            display: inline-block;
            vertical-align: top;
            min-height: 22px;
            line-height: 22px;
          }

          .staff {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .item-person {
            span {
              margin-right: 10px;
            }
          }
        }

        .flow-title {
          display: flex;
          align-items: center;

          .check-state-tag {
            white-space: nowrap;
            display: inline-block;
            border-radius: 6px;
            color: #fff;
            margin-right: 5px;
            line-height: 20px;
            padding: 0 6px;
            font-weight: 600;
            font-size: 0.75em;
          }
        }

        ul.item-icon-bar {
          padding: 6px 0 3px;
          margin: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-top: solid 1px #eee;

          li {
            list-style: none;
            color: #555555;
            //align-items: center;
            //justify-content: center;
            display: flex;

            span {
              font-size: 15px;
            }

            ion-icon {
              margin-right: 3px;
              font-size: 20px;
            }
          }
        }

        //.item:last-child {
        //  margin-bottom: 0 !important;
        //}
        ion-datetime {
          --padding-start: 0;
        }

        .flag {
          min-height: 22px;
          display: flex;
          //width: 100%;
          color: #999999;
          font-size: 0.9em;

          & > div {
            &:first-child {
              width: 50%;
              justify-content: flex-start;
            }

            ion-icon {
              margin-right: 5px;
            }

            width: 25%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
          }
        }

        .tags {
          span {
            display: inline-block;
            margin-right: 5px;
            border-radius: 3px;
            font-size: 0.8em;
            padding: 2px 6px;
            color: #ffffff;
            font-weight: 400;
            min-height: 22px;
            line-height: 22px;
          }
        }

        .bar-title {
          color: var(--ion-color-primary);
          font-weight: 500 !important;
          font-size: 1em;
          display: block;
          border-bottom: 1px solid #E1E2E5;
          padding: 0 0 8px;
          margin-bottom: 8px !important;
        }

        .bar-attrs {
          font-size: 0.9em !important;
          color: #6E6E6E;
          display: flex;
          align-items: center;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;

          .span-attr {
            max-width: calc(100% - 5.5em);
            display: block;
            vertical-align: top;
            min-height: 22px;
            line-height: 22px;
            text-align: center;
            padding: 0 6px;
            font-size: 0.85em;
            font-weight: 600;
            color: #fff;
            margin-right: 5px;
            border-radius: 3px;
            white-space: nowrap;
            overflow: hidden;
            min-width: 48px;


            &:nth-child(1) {
              background: #00CFAE;
            }

            &:nth-child(2) {
              background: #F4A100;
            }

            //&:first-child {
            //  background-color: #F4A100;
            //}
          }

          .right-span-attr {
            position: absolute;
            right: 10px;
          }
        }
      }
    }

    .panel-options-end {
      width: 100px;
      text-align: center;
      color: #ffffff;
    }

    ion-progress-bar {
      opacity: 0.5;
      margin-top: 5px;
    }

  }

  .item-selected {
    --background: rgba(82, 96, 255, 0.1);
  }
}


/// super-tab ////////////////////////////////////////

super-tabs-toolbar {
  box-shadow: 0px 5px 8px rgb(0 0 0 / 12%);

  &:after {
    background-image: none !important;
  }
}

super-tab-button {
  height: 50px;
  background: #ffffff;

  //box-shadow: 0px 5px 8px rgb(0 0 0 / 12%);

  ion-label {
    white-space: nowrap;
    font-size: 16px;
    font-weight: 450;
    color: var(--ion-color-dark);
    position: relative;

    .new-flag {
      position: absolute;
      top: -2px;
      width: 12px;
      height: 12px;
      border-radius: 6px;
      background-color: red;
      font-size: 8px;
      color: #ffffff;
      text-align: center;
      line-height: 12px;
    }
  }

  &.active ion-label {
    color: var(--ion-color-primary);
  }

  &.active {
    &:after {
      transition: all 1s;
      opacity: 1;
    }
  }

  &:after {
    content: " ";
    height: 3px;
    position: absolute;
    width: 65px;
    background: var(--ion-color-primary);
    bottom: 5px;
    border-radius: 2px;
    opacity: 0;
  }

}
