@mixin assets-image($logo) {
  //background-image: url(#{$file_path});
  background-image: url(#{$logo});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}


.mc-mobile {
  ion-grid, ion-col {
    padding: 0;
  }
}


.select-dep-staff-panel {
  width: 100%;
  display: flex;
  padding: 20px;
  height: 680px;
  font-size: 15px;

  .boy {
    color: #2f96f9 !important;
  }

  .girl {
    color: #ec1f89 !important;
  }

  .position {
    color: #777777;
    border: 1px solid #777;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 13px;
    padding: 1px 5px;
    margin-left: 6px;
  }

  .dep-staff {
    padding: 0 0 0 10px;
    width: 60%;
    position: relative;

    &:before {
      content: " ";
      width: 1px;
      height: 100%;
      background-color: #eee;
      right: 0;
      display: block;
      position: absolute;
    }

    ion-searchbar {
      height: 50px;
      padding: 0 26px 14px 0;
    }

    .dep-staff-tree {

      overflow: auto;
      height: calc(100% - 50px);

      .mc-tree-node-switcher {
        height: 28px;
        width: 26px !important;
        padding-top: 7px;

        ion-icon {
          font-size: 12px;
        }
      }

      .tree-node-head {
        margin: 0 3px;
      }

      .tree-node-item {
        display: flex;
        align-items: center;
        width: 100%;
        font-size: 15px;
        height: 28px;

        ion-icon {
          font-size: 16px;
          color: var(--ion-color-primary);
        }

        ion-label {
          margin-left: 8px;
          width: 100%;
          font-size: 15px;
        }

      }

      mc-treenode {
        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  .dep-staff-checked {
    padding: 0 6px 0 26px;
    width: 40%;

    .checked-count {
      height: 50px;
      //line-height: 40px;
      padding-right: 6px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span:first-child {
        //font-weight: 500;
      }

      span:last-child {
        white-space: nowrap;
        padding-left: 10px;
      }
    }

    .checked-list {
      overflow: auto;
      height: calc(100% - 100px);

      .checked-list-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 30px;

        div {
          display: flex;
          align-items: center;
          width: calc(100% - 32px);

          ion-icon {
            font-size: 16px;
            margin-right: 6px;
          }

          span {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }

        .btn-remove {
          cursor: pointer;
          padding: 3px 6px;

          ion-icon {
            font-size: 20px;
            color: #a8a8a8;
          }
        }

      }
    }

    .select-buttons {
      height: 50px;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;

      ion-button {
        font-weight: 400;
        min-width: 88px;
        margin-left: 8px;
      }
    }
  }
}


span.select-staff-block {
  word-wrap: break-word;
  white-space: normal;
  display: inline-block;
  //min-width: 56px;
  margin-right: 8px;
}

span.select-department-block {
  word-wrap: break-word;
  white-space: normal;
  display: inline-block;
  min-width: 100px;
  margin-right: 6px;
}