//ion-router-outlet {
//  top: 108px;
//}
.addressbook {

  .input-search {
    --inner-padding-end: 0px;

    ion-searchbar {
      --background: #DFE0E5;
      --color: #7F8592
    }
  }

  ion-label {
    --color: var(--ion-color-dark) !important;
  }

  ion-item {
    --padding-start: 16px;
    --padding-end: 16px;
    --min-height: 40px;
  }

  .deep-wrapper {
    padding: 0 16px;
    background: #ffffff;
  }

  .deep-panel {
    //margin: 0 16px;
    min-height: 40px;
    line-height: 40px;
    overflow-x: auto;
    white-space: nowrap;

    .deep {
      img {
        margin-right: 10px;
        margin-left: 3px;
      }

      ion-button {
        margin: 0;
        --padding-start: 0;
        --padding-end: 0;
        --color: var(--ion-color-primary);
      }

      .padd {
        min-width: 20px;
        text-align: center;
        color: #c0c0c0;
      }

      .chevron-right {
        min-width: 22px;
        font-size: 20px;
      }
    }
  }

  .group-list {
    ion-item:last-child {
      --border-color: #ffffff;
    }

    .group {
      --color: var(--ion-color-dark);
      --border-color: #D8D8D8;
      --inner-padding-end: 0px;
    }
  }

  .line {
    height: 8px;
    background: #F6F6F6;
  }

  .person-list {

    ion-item:last-child {
      --border-color: #ffffff;
    }

    .person {
      --border-color: #D8D8D8;

      .avatar {
        border-radius: 15px;
        text-align: center;
        width: 28px;
        height: 23px;
        line-height: 23px;
        color: #ffffff;
        font-weight: 500;
        font-size: 0.8em;
      }

      .boy {
        background: #00D1FF;
      }

      .girl {
        background: #FE92C6;
      }

      .name {
        margin-left: 12px;
        //border-bottom: solid 1px #e8e8e8;
        width: 100%;
        height: 44px;
        line-height: 44px;
        color: var(--ion-color-dark);

        label {
          display: inline-block;
        }

        small {
          border-radius: 8px;
          font-size: 0.9em;
          margin-left: 8px;
          border: solid 1px red;
          color: red;
          padding: 2px 5px;
        }

        .c1 {
          color: #00D1FF;
          border: 1px solid #00D1FF;
          box-sizing: border-box;
          border-radius: 4px;
        }

        .c2 {
          color: #72ca99;
          border-color: #72ca99;
        }
      }
    }
  }


}
